import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";

// Home Pages Import
// import ServiceTwo from "./pages/ServiceTwo";

// Css Import
import "./assets/scss/app.scss";
import ComingSoon from "./coming-soon/ComingSoon";
import ErrorPage from "./pages/404";

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path={process.env.PUBLIC_URL + "/"} element={<ComingSoon />} />
          <Route path={process.env.PUBLIC_URL + "*"} element={<ErrorPage />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default App;
